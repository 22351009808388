import {DriversController} from "../drivers_controller";

export default class extends DriversController {
  editDriver(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this._openModalForm(url);
  }

  onSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._successNotify(data.notification_title, data.notification_message);
      super._hideOrUpdateModalContent(window.layout_modal_xl, data);

      if (data.table_type === "sidebar") {
        Turbolinks.visit(data.url);
      } else {
        super._updateOrAddDataToTable(data.table_type, data);
      }
    }
  }

  onError(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._errorNotify(data.notification_title, data.notification_message);
      window.layout_modal_xl.setContent(data.form_content)
    }
  }
}