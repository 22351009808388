import Tabulator from "tabulator-tables";
import { DriversController } from "../drivers_controller";

export default class extends DriversController {
  static targets = [
    "search",
    "license_categories",
    "type_of_employments",
    "driving_experience_filter",
    "driving_experience_year",
    "age_filter",
    "age_year",
    "statuses",
    // table
    "table",
    // sidebar_sort
    "sidebar_sort", "sidebar_current_sort"
  ]

  connect() {
    // Sidebar
    if (this.hasTableTarget) {
      this._createSidebarTable()
        .then(() => {
          console.log('Sidebar table was successfully created.');
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }

  // Sidebar Sort
  sidebarSort(event) {
    let sort_target = event.currentTarget;
    let sort_column = sort_target.dataset.sortColumn;
    let sort_dir = sort_target.dataset.sortDir;
    let table = this.tableTarget.tabulator;

    // change sort dir
    if (sort_dir === "asc") sort_dir = "desc"
    else sort_dir = "asc"

    sort_target.dataset.sortDir = sort_dir

    // set sort to table
    table.setSort(sort_column, sort_dir);

    // change links
    this.sidebar_sortTargets.forEach(el => {
      el.classList.remove("active");
      el.innerHTML = el.dataset.sortColumnName;
    });
    sort_target.classList.add("active");
    // set new icon
    const new_html = this._getSidebarSortHtml(sort_target.dataset.sortColumnName, sort_dir);
    sort_target.innerHTML = new_html;
    if (this.hasSidebar_current_sortTarget) this.sidebar_current_sortTarget.innerHTML = new_html;
  }

  // Sidebar Filter
  filterTable() {
    let filters = this._getPageFilters();
    console.log(filters);
    this._setSidebarFilterToTable(filters).then(() => {
      console.log('Active table was successfully filtered.');
    }).catch(error => {
      console.error('Active table filtered Error:', error);
    });
  }

  //
  // Private
  //
  async _setSidebarFilterToTable(filters) {
    let tabulator = this.tableTarget.tabulator;
    if (!this.hasTableTarget || !tabulator) return;
    console.log(filters);
    await this._setFilterToTable(tabulator, filters).then(() => {
      console.log('Sidebar table was successfully filtered.');
    }).catch(error => {
      console.error('Sidebar table filtered Error:', error);
    });
  }

  // gen icon html
  _getSidebarSortHtml(column_name, sort_dir) {
    let up_or_down = "up";
    if (sort_dir === "asc") up_or_down = "down"
    return column_name + "&nbsp; <i class='fa-sort-alpha-" + up_or_down + " fas sort_dir'></i>";
  }

  // Create Sidebar table
  async _createSidebarTable() {
    const table_target = this.tableTarget;
    let table_props = this._buildTableProps;
    //
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  get _buildTableProps() {
    const columns = this.data.get("table-columns");
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");
    //
    const url = this.data.get("table-load-url");
    //
    let formatRow = super._defaultFormatRow
    let rowClick = super._defaultRowClick
    //
    let props = {
      columns: JSON.parse(columns),
      // sort
      initialSort: [{ column: sort_column, dir: sort_dir }],
      initialFilter: this._getPageFilters(),
      //
      rowFormatter: function (row) { formatRow(row) },
      //
      rowClick: function (e, row) { rowClick(e, row) }
    }
    //
    return Object.assign(super._getDefaultSidebarTableProps(url), props);
  }
}