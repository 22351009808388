import moment from "moment";
import {DriversController} from "../drivers_controller";

export default class extends DriversController {
  static targets = [
    'user_fields_link',
    'user_id_field',
    'user_fields',
    'user_field',
    'form_driving_experience',
    'form_driver_license_issue_date'
  ]

  newVehicle(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this._openModalForm(url);
  }

  onSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._successNotify(data.notification_title, data.notification_message);
      super._hideOrUpdateModalContent(window.layout_modal_xl, data);
      super._updateOrAddDataToTable(data.table_type, data);

      if (data.table_type === "sidebar") Turbolinks.visit(data.url);
    }
  }

  onError(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._errorNotify(data.notification_title, data.notification_message);
      window.layout_modal_xl.setContent(data.form_content)
    }
  }

  showUserFields(event) {
    event.preventDefault();
    this._showOrHideUserFields();
    this._showOrHideUserId();
    this._changeUserFieldsLinkText();
  }

  setDriverLicenseIssueDate(event) {
    let date = event.target.value;
    let experienceField = document.getElementById('driving_experience');
    let experience_years = this._callculateExperience(date);
    experienceField.value = experience_years;
  }

  // PRIVATE

  _callculateExperience(date) {
    let formattedDate = moment(date, 'MM-DD-YYYY');
    return moment().diff(formattedDate, 'years');
  }

  _showOrHideUserId() {
    if (this.user_id_fieldTarget.disabled) {
      this.user_id_fieldTarget.choices.enable();
    } else {
      this.user_id_fieldTarget.choices.disable();
    }
  }

  _changeUserFieldsLinkText() {
    let showText = this.user_fields_linkTarget.dataset.showText;
    let hideText = this.user_fields_linkTarget.dataset.hideText;

    if (this.user_fields_linkTarget.innerText === showText) {
      this.user_fields_linkTarget.innerText = hideText;
    } else {
      this.user_fields_linkTarget.innerText = showText;
    }
  }

  _showOrHideUserFields() {
    if (this.user_fieldsTarget.classList.contains('hidden')) {
      this._showUserFields();
    } else {
      this._hideUserFields();
    }
  }

  _showUserFields() {
    this.user_fieldTargets.forEach((field) => {
      if (field.choices) {
        console.log(field);
        field.choices.enable();
      } else {
        field.disabled = false;
      }
    });

    this.user_fieldsTarget.classList.remove('hidden');
  }

  _hideUserFields() {
    this.user_fieldTargets.forEach((field) => {
      if (field.choices) {
        field.choices.disable();
      } else {
        field.disabled = true;
      }
    });
    this.user_fieldsTarget.classList.add('hidden');
  }
}