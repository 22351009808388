import { TransportMainController } from "../transport_main_controller";

export class IndividualsController extends TransportMainController {

  //
  // Protected
  //
  _openModalForm(url) {
    super.openLayoutModalMD(url).then(() => {
      console.log('Modal was successfully opened.');
    }).catch(error => {
      console.error('Error:', error);
    });
  }

  _sidebarTableUpdateOrAddData(row_data) {
    let table = this._sidebarTabulator;
    if (table) table.updateOrAddData([row_data]);
  }

  _activeTableUpdateOrAddData(row_data) {
    let table = this._activeTabulator;
    if (table) table.updateOrAddData([row_data]);
  }

  _archiveTableUpdateOrAddData(row_data) {
    let table = this._archiveTabulator;
    if (table) table.updateOrAddData([row_data]);
  }

  _updateOrAddDataToTable(table_type, data) {
    if (table_type === 'sidebar') {
      this._sidebarTableUpdateOrAddData(data.sidebar_row_data);
    } else {
      this._activeTableUpdateOrAddData(data.row_data);
    }
  }

  //
  // Private
  //
  get _sidebarTabulator() {
    let table_target = document.getElementById("transport--individuals-sidebar-table");
    if (table_target.tabulator) {
      return table_target.tabulator;
    }
  }

  get _activeTabulator() {
    let table_target = document.getElementById("transport--individuals-active-table");
    if (table_target.tabulator) {
      return table_target.tabulator;
    }
  }

  get _archiveTabulator() {
    let table_target = document.getElementById("transport--individuals-archive-table");
    if (table_target.tabulator) {
      return table_target.tabulator;
    }
  }

  // Filters
  _getPageFilters() {
    let filters = []

    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasArchiveTarget) {
      if (this.archiveTarget.checked) {
        filters.push({
          field: "archive",
          type: "=",
          value: this.archiveTarget.checked
        })
      }
    }

    return filters;
  }
}