import {DriversController} from "../drivers_controller";

export default class extends DriversController {
  static targets = ["delete_link"]

  destroyDriver(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this._deleteDriver(url);
  }

  //
  // Private
  //
  _deleteDriver(url) {
    let confirmMessage = this.data.get("confirm-text");
    if (confirm(confirmMessage)) {
      Rails.ajax({
        url: url,
        type: 'delete',
        success: (data) => {
          if (data) {
            super._successNotify(data.notification_title, data.notification_message);
            this._removeRowFromTable(data.table_type, data);
          }
        },
        error: (data) => {
          if (data) {
            super._errorNotify(data.notification_title, data.notification_message);
          }
        }
      })
    }
  }

  _removeRowFromTable(table_type, data) {
    switch (table_type) {
      case "sidebar":
        Turbolinks.visit(data.url);
        break;
      case "active":
        super._deleteTabulatorRowById(this._activeTabulator, data.id);
        break;
      case "archive":
        super._deleteTabulatorRowById(this._archiveTabulator, data.id);
        break;
    }
  }
}