import { TransportMainController } from "../transport_main_controller";

export class DriversController extends TransportMainController {

  //
  // Protected
  //
  _openModalForm(url) {
    super.openLayoutModalXL(url).then(() => {
      console.log('Modal was successfully opened.');
    }).catch(error => {
      console.error('Error:', error);
    });
  }

  _sidebarTableUpdateOrAddData(row_data) {
    let table = this._sidebarTabulator;
    if (table) table.updateOrAddData([row_data]);
  }

  _activeTableUpdateOrAddData(row_data) {
    let table = this._activeTabulator;
    if (table) table.updateOrAddData([row_data]);
  }

  _archiveTableUpdateOrAddData(row_data) {
    let table = this._archiveTabulator;
    if (table) table.updateOrAddData([row_data]);
  }

  _updateOrAddDataToTable(table_type, data) {
    console.log(table_type);
    switch (table_type) {
      case "sidebar":
        this._sidebarTableUpdateOrAddData(data.sidebar_row_data);
        break;
      case "active":
        this._activeTableUpdateOrAddData(data.row_data);
        break;
      case "archive":
        this._archiveTableUpdateOrAddData(data.row_data);
        break;
    }
  }

  //
  // Private
  //
  get _sidebarTabulator() {
    let table_target = document.getElementById("transport--drivers-sidebar-table");
    if (table_target.tabulator) {
      return table_target.tabulator;
    }
  }

  get _activeTabulator() {
    let table_target = document.getElementById("transport--drivers-active-table");
    if (table_target.tabulator) {
      return table_target.tabulator;
    }
  }

  get _archiveTabulator() {
    let table_target = document.getElementById("transport--drivers-archive-table");
    if (table_target.tabulator) {
      return table_target.tabulator;
    }
  }

  // Filters
  _getPageFilters() {
    let filters = []

    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasLicense_categoriesTarget) {
      filters.push({
        field: "license_categories",
        type: "=",
        value: super._getSelectCollectionValues(this.license_categoriesTarget)
      })
    }

    if (this.hasDriving_experience_filterTarget) {
      filters.push({
        field: "driving_experience_filter",
        type: "=",
        value: this.driving_experience_filterTarget.value
      })
    }

    if (this.hasDriving_experience_yearTarget) {
      filters.push({
        field: "driving_experience_year",
        type: "=",
        value: this.driving_experience_yearTarget.value
      })
    }

    if (this.hasAge_filterTarget) {
      filters.push({
        field: "age_filter",
        type: "=",
        value: this.age_filterTarget.value
      })
    }

    if (this.hasAge_yearTarget) {
      filters.push({
        field: "age_year",
        type: "=",
        value: this.age_yearTarget.value
      })
    }

    if (this.hasStatusesTarget) {
      filters.push({
        field: "statuses",
        type: "=",
        value: super._getSelectCollectionValues(this.statusesTarget)
      })
    }

    if (this.hasType_of_employmentsTarget) {
      if (this.type_of_employmentsTarget.value.length) {
        filters.push({
          field: "type_of_employments",
          type: "=",
          value: super._getSelectCollectionValues(this.type_of_employmentsTarget)
        })
      }
    }

    return filters;
  }
}